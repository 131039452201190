<template>
  <div class="modal__buttons">
    <hcc-button
      v-if="buttons.includes('cancel')"
      @click="handleCancel"
      size="sm"
      variant="outline"
      color="muted"
      >{{ $t("shared-components.modal.cancel") }}</hcc-button
    >
    <hcc-button
      v-if="buttons.includes('confirm')"
      :disabled="disabled"
      @click="handleConfirm"
      size="sm"
      class="modal__buttons--button"
      :color="getButtonColor"
      >{{ $t("shared-components.modal.confirm") }}</hcc-button
    >
    <hcc-button
      v-if="buttons.includes('exit')"
      @click="handleCancel"
      size="sm"
      variant="outline"
      color="muted"
      >{{ $t("shared-components.modal.exit") }}</hcc-button
    >
  </div>
</template>

<script>
import HccButton from '../HccButton/index.vue';

export default {
  components: {
    HccButton,
  },
  props: {
    variant: {
      type: String,
      default: 'alert',
    },
    buttons: {
      type: Array,
      default: () => ['cancel', 'confirm'],
    },
    handleConfirm: {
      type: Function,
    },
    handleCancel: {
      type: Function,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    getButtonColor() {
      let color = 'secondary';

      if (this.variant === 'error') {
        color = 'alert';
      } else if (this.variant === 'info') {
        color = 'primary';
      }
      return color;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/shared/modals.scss";
</style>
